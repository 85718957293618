import { useMutation } from '@apollo/react-hooks';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackProductEvent } from '@jetshop/core/analytics/tracking';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import { addToCartSuccess } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { useNotification } from '@jetshop/core/components/Notifications';
import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';
import React, { useContext } from 'react';
import ProductToastWrapper from '../ProductPage/AddToCart/ProductToast';
import { addToCart } from './addToCart.gql';

const AddToCart = ({
  children,
  product,
  selectedVariation = null,
  quantity = 1,
}) => {
  const { cartId, setCartId } = useContext(CartIdContext);
  const [trigger] = useNotification();
  const track = useTracker();
  const articleNumber =
    selectedVariation?.articleNumber || product.articleNumber;

  const price = useDynamicPrice(product, selectedVariation);

  const [add] = useMutation(addToCart, {
    variables: {
      input: {
        cartId,
        articleNumber,
        quantity: quantity,
      },
    },
    onCompleted: (data) => {
      //TRACK ADD TO CART
      track(
        trackProductEvent({
          action: 'add',
          product,
          selectedVariant: selectedVariation,
        })
      );

      //SHOW TOASTER
      trigger(
        <ProductToastWrapper
          selectedVariation={selectedVariation}
          product={product}
          quantity={quantity}
          price={price}
        />,
        {
          type: 'add-to-cart',
        }
      );
      addToCartSuccess({ cartId, setCartId })({ data });
    },
  });
  return children(add);
};

export default AddToCart;
