import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';

export const ProductListHeader = styled('h2')`
  padding: 0.3rem 0 2rem;
  width: 100%;
  text-align: center;
  color: ${theme.productPage.productList.headerColor};
`;

export const ProductListSubHeader = styled('h4')`
  padding: 0 0;
  width: 100%;
  text-align: center;
  color: ${theme.productPage.productList.subHeaderColor};
  font-weight: ${theme.weights.bold} !important;
`;

export const ProductInfoMarginBottom = `
  margin-bottom: 0.6rem;
  ${theme.above.md} {
    margin-bottom: 1rem;
  }
`;
