import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import t from '@jetshop/intl';
import ProductLink from '@jetshop/ui/ProductLink';
import { styled } from 'linaria/react';
import React, { useEffect, useMemo } from 'react';
import AddToCart from '../../Cart/AddToCart';
import { ProductCard } from '../../CategoryPage/ProductCard';
import { GridWrapper } from '../../CategoryPage/ProductGrid';
import {
  ProductListHeader,
  ProductListSubHeader,
} from '../../ProductPage/StyledComponents';
import { theme } from '../../Theming/Theming';
import { ButtonUI } from '../ButtonUI';
import { ScrollBox } from '../ScrollBox';

const ProductRowWrapper = styled('div')`
  width: 100%;

  //SLIDER WIDTH
  .slider-items {
    .product-card {
      margin-right: 2%;
      ${theme.only.sm} {
        width: 55%;
        margin-right: 4%;
      }
      ${theme.only.md} {
        width: 37%;
        margin-right: 3%;
      }
      ${theme.only.lg} {
        width: 27%;
      }
      ${theme.above.xl} {
        width: 21%;
      }
    }
  }
  &.clean {
    .slider-items {
      .product-card {
        margin-right: 2%;
        ${theme.only.sm} {
          width: 19%;
          margin-right: 4%;
        }
        ${theme.only.md} {
          width: 15%;
          margin-right: 3%;
        }
        ${theme.only.lg} {
          width: 12%;
        }
        ${theme.above.xl} {
          width: 9%;
        }
      }
    }
    margin-top: 3rem;
    .product-list-header {
      color: ${theme.colors.primaryDark};
      text-align: left;
      font-size: 1.4rem;
    }
    .product-card {
      .product-card-detail {
        display: none;
      }
    }
  }
  &.tight {
    .product-card-detail {
      margin: 0.5rem 0 0.1rem;
    }
    header {
      > h5 {
        font-size: 0.8rem;
        margin-bottom: 2px;
      }
      > a > h4 {
        font-size: 0.8rem;
        margin-bottom: 5px !important;
      }
    }
    [data-flight-price] > div {
      font-size: 0.9rem;
    }
    button.buy {
      margin-top: 7px;
      font-size: 0.8rem;
    }
  }
  &.tight .slider-items {
    .product-card {
      margin-right: 2%;
      ${theme.only.sm} {
        width: 45%;
        margin-right: 4%;
      }
      ${theme.only.md} {
        width: 27%;
        margin-right: 3%;
      }
      ${theme.only.lg} {
        width: 27%;
      }
      ${theme.above.xl} {
        width: 17%;
      }
    }
  }
`;

const RowProduct = ({ product, quickBuy }) => {
  return (
    <ProductCard product={product} categoryPath={null}>
      {quickBuy && (
        <>
          {!product.hasVariants &&
          product.stockStatus &&
          product.stockStatus.buyable ? (
            <AddToCart
              product={product}
              quantity={1}
              selectedVariation={product}
            >
              {(add) => (
                <ButtonUI
                  css={{ marginTop: '1rem' }}
                  className="buy list"
                  onClick={() => {
                    add();
                  }}
                >
                  {t('Add to cart')}
                </ButtonUI>
              )}
            </AddToCart>
          ) : (
            <ButtonUI className="hollow list add-to-cart">
              <ProductLink product={product} categoryPath={null}>
                {t('Go to')}
              </ProductLink>
            </ButtonUI>
          )}
        </>
      )}
    </ProductCard>
  );
};

export const RowProducts = ({
  category,
  slider,
  title,
  subTitle,
  quickBuy,
  ...rest
}) => {
  const products = useMemo(() => {
    return category && category.products && category.products.result
      ? category.products.result
      : [];
  }, [category]);

  const listName = 'product-row: ' + category.name;

  //COMBINE STATIC WITH SLIDER VIEW
  const hybridSlider = products.length === 4 && slider;

  //TRACK LIKE PRODUCTGRID
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;
    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <ProductRowWrapper data-testid="product-grid" className={rest.className}>
      {subTitle && <ProductListSubHeader>{subTitle}</ProductListSubHeader>}
      {title && <ProductListHeader>{title}</ProductListHeader>}
      {slider ? (
        <ScrollBox
          className={hybridSlider ? 'hybrid' : ''}
          progressBar={rest.progressBar}
        >
          {products.map((product) => (
            <RowProduct
              product={product}
              quickBuy={quickBuy}
              key={category.id + '-' + product.id}
            />
          ))}
        </ScrollBox>
      ) : (
        <GridWrapper>
          {products.map((product) => {
            return (
              <RowProduct
                product={product}
                quickBuy={quickBuy}
                key={category.id + '-' + product.id}
              />
            );
          })}
        </GridWrapper>
      )}
    </ProductRowWrapper>
  );
};
